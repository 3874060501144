import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import {
  faFileAlt,
  faHandshake,
  faClipboardCheck,
  faBalanceScale,
  faTools,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

function Home() {
  const [showArrow, setShowArrow] = useState(true); // Track arrow visibility

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowArrow(false); // Hide arrow when user scrolls past 50px
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true,
  };

  const images = ["/hero.jpg", "/hero2.jpg", "/hero3.jpg"];

  return (
    <div className="transition-colors duration-300 bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-200">
      {/* Hero Section */}
      <section className="relative h-screen overflow-hidden">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="h-screen">
              <div
                className="h-full w-full"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            </div>
          ))}
        </Slider>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
          <h1 className="text-5xl md:text-6xl font-bold mb-4">
            Sell Your Self-Storage Facility with Ease
          </h1>
          <p className="text-lg md:text-2xl mb-8">
            We specialize in purchasing self-storage facilities nationwide. Experience a stress-free sale with our expert team.
          </p>
          <div className="space-x-4">
            <a
              href="/about"
              className="bg-white text-blue-600 px-6 py-3 rounded-md shadow-md font-bold hover:bg-gray-200 transition-all"
            >
              Learn More
            </a>
            <a
              href="/contact"
              className="bg-red-600 text-white px-6 py-3 rounded-md shadow-md font-bold hover:bg-red-500 transition-all"
            >
              Contact Us
            </a>
          </div>
        </div>

        {/* Scroll Down Arrow */}
        {showArrow && (
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 animate-bounce">
            <FontAwesomeIcon
              icon={faArrowDown}
              className="text-white text-4xl"
            />
          </div>
        )}
      </section>

      {/* How It Works Section */}
      <section className="bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-200 py-16 transition-colors duration-300">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-bold mb-10">How It Works</h2>
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-1/3 px-6 mb-12">
              <FontAwesomeIcon icon={faFileAlt} className="text-blue-500 text-7xl mb-6" />
              <h3 className="text-2xl font-semibold mb-4">1. Submit Your Property Details</h3>
              <p>
                Fill out our simple online form with your property details. Provide us with basic information about your facility.
              </p>
            </div>
            {/* Step 2 */}
            <div className="w-full md:w-1/3 px-6 mb-12">
              <FontAwesomeIcon icon={faHandshake} className="text-green-500 text-7xl mb-6" />
              <h3 className="text-2xl font-semibold mb-4">2. Receive Your Offer</h3>
              <p>Our team will review your details and prepare a fair, no-obligation offer for your property.</p>
            </div>
            {/* Step 3 */}
            <div className="w-full md:w-1/3 px-6 mb-12">
              <FontAwesomeIcon icon={faClipboardCheck} className="text-red-500 text-7xl mb-6" />
              <h3 className="text-2xl font-semibold mb-4">3. Close the Deal</h3>
              <p>Once you accept our offer, we handle everything, ensuring a smooth and hassle-free process.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 py-16 transition-colors duration-300">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl font-bold mb-10">Why Choose Us</h2>
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-1/3 px-6 mb-12">
              <FontAwesomeIcon icon={faBalanceScale} className="text-blue-500 text-7xl mb-6" />
              <h3 className="text-2xl font-semibold mb-4">Fair Offers</h3>
              <p>We provide competitive and transparent offers for your property.</p>
            </div>
            <div className="w-full md:w-1/3 px-6 mb-12">
              <FontAwesomeIcon icon={faTools} className="text-green-500 text-7xl mb-6" />
              <h3 className="text-2xl font-semibold mb-4">Hassle-Free</h3>
              <p>We buy your property as-is, saving you time and effort on repairs.</p>
            </div>
            <div className="w-full md:w-1/3 px-6 mb-12">
              <FontAwesomeIcon icon={faClock} className="text-yellow-500 text-7xl mb-6" />
              <h3 className="text-2xl font-semibold mb-4">Fast and Flexible</h3>
              <p>We work on your timeline to ensure the sale meets your needs.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-200 py-16 text-center transition-colors duration-300">
        <h2 className="text-4xl font-bold mb-4">Ready to Sell Your Self-Storage Facility?</h2>
        <p className="mb-8 text-lg">
          Contact us today and take the first step toward a hassle-free sale.
        </p>
        <a
          href="/contact"
          className="bg-blue-600 text-white px-8 py-4 rounded-md shadow-md text-lg font-bold hover:bg-blue-500 dark:bg-blue-500 dark:hover:bg-blue-400 transition-all duration-300"
        >
          Contact Us
        </a>
      </section>
    </div>
  );
}

export default Home;
