import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { trackEvent } from "../analytics";

function Navbar() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Detect initial system theme
  useEffect(() => {
    const darkModePreference = window.matchMedia("(prefers-color-scheme: dark)");

    const initialMode = darkModePreference.matches;
    setIsDarkMode(initialMode); // Set the initial dark mode state

    if (initialMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  // Detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.nav
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`flex items-center justify-between px-4 py-3 fixed top-0 w-full z-50 transition-all duration-300 ${
        isScrolled
          ? isDarkMode
            ? "bg-gray-900 bg-opacity-90 shadow-md" // Dark mode on scroll
            : "bg-gray-100 bg-opacity-90 shadow-md" // Light mode on scroll
          : "bg-transparent" // Transparent when not scrolled
      }`}
    >
      {/* Logo */}
      <Link to="/" onClick={() => trackEvent("Navigation", "Clicked Home", "Navbar")}>
        <img
          src={isDarkMode ? "/am_self_storage_logo_dark.png" : "/am_self_storage_logo_light.png"}
          alt="Logo"
          className="h-16 w-auto"
        />
      </Link>

      {/* Navigation Links */}
      <ul
        className={`flex space-x-6 text-lg font-semibold ${
          isDarkMode ? "text-gray-100" : "text-black"
        }`}
      >
        <li>
          <Link
            to="/"
            className="hover:text-blue-400 transition duration-300"
            onClick={() => trackEvent("Navigation", "Clicked Home", "Navbar")}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className="hover:text-blue-400 transition duration-300"
            onClick={() => trackEvent("Navigation", "Clicked About", "Navbar")}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            className="hover:text-blue-400 transition duration-300"
            onClick={() => trackEvent("Navigation", "Clicked Contact", "Navbar")}
          >
            Contact
          </Link>
        </li>
      </ul>
    </motion.nav>
  );
}

export default Navbar;
