import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";

function About() {
  return (
    <div className="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 min-h-screen flex flex-col justify-between transition-colors duration-300">
      {/* About Section */}
      <div className="container mx-auto py-16 px-8">
        <h1 className="text-5xl font-bold text-center mb-8">About Me</h1>
        <p className="text-lg text-center max-w-4xl mx-auto mb-8">
          Hi, I’m <strong>Kunal</strong>, the founder of <strong>AM Self Storage</strong>.
          I’ve been actively involved in real estate investing, both directly and through syndication, for the last 10 years.
          While I work in the tech industry, I’ve developed a deep passion for self-storage investing.
          This isn’t just a business for me—it’s a way to connect with property owners and provide solutions that truly matter.
        </p>
        <p className="text-lg text-center max-w-4xl mx-auto mb-8">
          I understand that selling a property is a significant decision, and
          I’m committed to making the process as smooth and stress-free as possible.
          When you work with me, you’re not dealing with a big, impersonal company—you’re partnering with someone who
          genuinely cares about your goals and values personal relationships.
        </p>

        {/* Mission Statement */}
        <div className="bg-gray-200 dark:bg-gray-800 py-8 px-6 rounded-md shadow-lg max-w-4xl mx-auto text-center mb-16">
          <h2 className="text-3xl font-bold mb-4">Why I Do This</h2>
          <p className="text-lg">
            My mission is simple: to help self-storage owners like you transition to the next chapter of your lives.
            I believe in fair offers, transparent communication, and a process that respects your time and needs.
          </p>
        </div>

        {/* Personal Story Section */}
        <div className="text-center max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold mb-4">A Little About Me</h2>
          <p className="text-lg mb-8">
            My journey into self-storage began six years ago, after transitioning from single-family home investments.
            I realized that many property owners were frustrated with the traditional sales process—dealing
            with brokers, repairs, and endless negotiations. Since then, I’ve been dedicated to building a
            streamlined and stress-free process for sellers.
          </p>
          <p className="text-lg mb-8">
            Outside of work, I enjoy exploring local coffee shops, spending quality time with my family,
            especially taking care of my two sons, and embarking on weekend road trips to discover new places.
            I believe life is about building meaningful relationships and finding joy in the little moments that truly matter.
          </p>
        </div>

        {/* Social Media Links */}
        <div className="text-center mt-12">
          <h2 className="text-3xl font-bold mb-6">Connect with Me</h2>
          <div className="flex justify-center space-x-8">
            {/* Facebook */}
            <a
              href="https://www.facebook.com/kunalmudgal/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300 transition-transform transform hover:scale-110 duration-300"
            >
              <FontAwesomeIcon icon={faFacebook} size="4x" />
            </a>
            {/* LinkedIn */}
            <a
              href="https://www.linkedin.com/in/kunal-mudgal-0b417211/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 dark:text-blue-300 hover:text-blue-400 dark:hover:text-blue-200 transition-transform transform hover:scale-110 duration-300"
            >
              <FontAwesomeIcon icon={faLinkedin} size="4x" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
