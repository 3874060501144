import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import UnderWriting from "./pages/UnderWriting";
import React, { useEffect } from "react";
import { initializeAnalytics, trackPageView } from "./analytics";

// Custom Hook for Page View Tracking
const useTrackPageViews = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search); // Track page view with path and query params
  }, [location]);
};

function App() {
  useEffect(() => {
    initializeAnalytics(); // Initialize Google Analytics when the app loads
  }, []);

  return (
    <Router>
      <PageViewTracker /> {/* Ensure page views are tracked within Router context */}
      <div className="bg-gray-900 text-gray-100">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/uw" element={<UnderWriting />} />
        </Routes>
        {/* Footer */}
        <footer className="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 py-8 text-center transition-colors duration-300">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} AM Self Storage. All rights reserved. Built with care.
          </p>
          <div className="mt-4 flex justify-center space-x-4">
            <a
              href="/about"
              className="text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
            >
              About Us
            </a>
            <a
              href="/contact"
              className="text-gray-600 dark:text-gray-400 hover:text-blue-500 dark:hover:text-blue-400 transition-colors duration-300"
            >
              Contact Us
            </a>
          </div>
        </footer>
      </div>
    </Router>
  );
}

// Wrapper Component to Use Page Tracking
function PageViewTracker() {
  useTrackPageViews(); // Call the tracking hook inside the Router context
  return null;
}

export default App;
