import React, { useState } from "react";
import axios from "axios";
import { trackEvent } from "../analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const apiURL = process.env.REACT_APP_API_URL;

function Contact() {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    propertyAddress: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    trackEvent("Form", "Submitted Contact Form", "Contact Page");

    try {
      const response = await axios.post(`${apiURL}/contact`, form);
      if (response.data.success) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to send your message. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 min-h-screen flex items-center justify-center py-16 transition-colors duration-300">
      {!submitted ? (
        <div className="container max-w-3xl bg-gray-200 dark:bg-gray-800 p-8 rounded-md shadow-lg transition-colors duration-300">
          <h2 className="text-4xl font-bold text-center mb-6">Contact Us</h2>
          <p className="text-xl text-center mb-8">
            Have questions or want to get in touch? Fill out the form below, and we’ll get back to you as soon as possible.
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name Field */}
            <div>
              <label htmlFor="name" className="block text-xl font-medium mb-2">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="John Doe"
                className="w-full px-4 py-3 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
                required
              />
            </div>
            {/* Email Field */}
            <div>
              <label htmlFor="email" className="block text-xl font-medium mb-2">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                placeholder="yourname@example.com"
                className="w-full px-4 py-3 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                required
              />
            </div>
            {/* Phone Field */}
            <div>
              <label htmlFor="phone" className="block text-xl font-medium mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                placeholder="(123) 456-7890"
                className="w-full px-4 py-3 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                value={form.phone}
                onChange={(e) => setForm({ ...form, phone: e.target.value })}
                required
              />
            </div>
            {/* Property Address Field */}
            <div>
              <label htmlFor="propertyAddress" className="block text-xl font-medium mb-2">
                Property Address
              </label>
              <input
                type="text"
                id="propertyAddress"
                placeholder="123 Storage Lane, City, State"
                className="w-full px-4 py-3 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                value={form.propertyAddress}
                onChange={(e) => setForm({ ...form, propertyAddress: e.target.value })}
                required
              />
            </div>
            {/* Message Field */}
            <div>
              <label htmlFor="message" className="block text-xl font-medium mb-2">
                Your Message
              </label>
              <textarea
                id="message"
                rows="5"
                placeholder="Write your message here..."
                className="w-full px-4 py-3 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                value={form.message}
                onChange={(e) => setForm({ ...form, message: e.target.value })}
                required
              ></textarea>
            </div>
            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-600 hover:bg-blue-500 text-white font-bold py-3 px-6 rounded-md shadow-md transition-all duration-300 text-lg dark:bg-blue-500 dark:hover:bg-blue-400"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="text-center transition-colors duration-300">
          <h2 className="text-5xl font-bold mb-6">Thank You!</h2>
          <p className="text-xl mb-8">
            Your message has been received. We’ll be in touch shortly. In the meantime, feel free to connect with us on social media:
          </p>
          <div className="flex justify-center space-x-6">
            {/* Facebook Link */}
            <a
              href="https://www.facebook.com/kunalmudgal/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-400 hover:text-blue-500 dark:hover:text-blue-300 transition-all duration-300"
            >
              <FontAwesomeIcon icon={faFacebook} size="3x" />
            </a>
            {/* LinkedIn Link */}
            <a
              href="https://www.linkedin.com/in/kunal-mudgal-0b417211/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 dark:text-blue-300 hover:text-blue-400 dark:hover:text-blue-200 transition-all duration-300"
            >
              <FontAwesomeIcon icon={faLinkedin} size="3x" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default Contact;
