import React, { useState } from "react";

function UnderWriting() {
  const [address, setAddress] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch(
        "https://us-central1-your-project-id.cloudfunctions.net/ud", // Replace with your function URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ address }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch results.");
      }

      const data = await response.json();
      setResult(data.result);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex items-center justify-center">
      <div className="container max-w-lg bg-white dark:bg-gray-800 p-8 rounded-md shadow-md">
        <h1 className="text-2xl font-bold mb-6 text-center">Run Property Analysis</h1>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="address" className="block text-lg font-medium mb-2">
              Property Address:
            </label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-blue-400 dark:border-gray-700 dark:bg-gray-700 dark:text-gray-200"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-500 transition"
          >
            Submit
          </button>
        </form>
        {result && (
          <div className="mt-6 p-4 bg-green-100 text-green-700 rounded-md">
            <h2 className="font-bold">Result:</h2>
            <p>{result}</p>
          </div>
        )}
        {error && (
          <div className="mt-6 p-4 bg-red-100 text-red-700 rounded-md">
            <h2 className="font-bold">Error:</h2>
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default UnderWriting;
